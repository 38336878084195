import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let TurmaModule = class TurmaModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.turmas = [];
        this.turmaFicha = {};
        this.turmaFichaId = 0;
    }
    setTurmas(payload) {
        this.turmas = payload;
    }
    setTurmaFicha(payload) {
        this.turmaFicha = payload;
    }
    setTurmaFichaId(turmaId) {
        this.turmaFichaId = turmaId;
    }
    setTurma(payload) {
        const turmas = this.turmas.filter((company) => company.id !== payload.id);
        turmas.push(payload);
        this.turmas = turmas;
    }
    async createTurma(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createTurma(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Turma criada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateTurma(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateTurma(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Turma atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async ajustarTurma(id) {
        const loadingNotification = { content: 'Ajustando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.ajustaTurma(mainStore.token, id),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Turma ajustada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateCronograma({ data, id }) {
        try {
            const response = await api.updateCronograma(mainStore.token, data, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCronograma(id) {
        try {
            const response = await api.getCronograma(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmas(payload) {
        try {
            const response = await api.getTurmas(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmasCompativelTransferencia(turmaAlunoId) {
        try {
            const response = await api.getTurmasCompativelTransferencia(mainStore.token, turmaAlunoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmaQuery(queryIn) {
        try {
            const response = await api.getTurmaQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmasByProfessorId(payload) {
        try {
            const response = await api.getTurmasByProfessorId(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmasAlunosByTurma(payload) {
        try {
            const response = await api.getTurmasAlunosByTurma(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmasByProfessor() {
        try {
            const response = await api.getTurmasByProfessor(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async imprimirFichaFrequencia(id) {
        try {
            const response = await api.imprimirFichaFrequencia(mainStore.token, id);
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async versoFichaFrequencia(payload) {
        try {
            const response = await api.versoFichaFrequencia(mainStore.token, payload.id, payload.exibirSomenteVigente);
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurma(id) {
        try {
            const response = await api.getTurma(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async setEntregaLivro(payload) {
        try {
            const response = await api.setEntregaLivro(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmaAlunos(payload) {
        try {
            const response = await api.getTurmaAlunos(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmaAlunosSimplificado(turmaId) {
        try {
            const response = await api.getTurmaAlunosSimplificado(mainStore.token, turmaId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getTurmaAlunosFicha(turmaId) {
        try {
            if (turmaId != this.turmaFichaId) {
                const response = await api.getTurmaAlunosFicha(mainStore.token, turmaId);
                if (response) {
                    this.setTurmaFichaId(turmaId);
                    this.setTurmaFicha(response.data);
                }
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createTurmaAluno(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createTurmaAluno(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Aluno inserido na turma com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getTurmaAlunosAtividade(payload) {
        try {
            const response = await api.getTurmaAlunosAtividade(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteTurma(turmaId) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.deleteTurma(mainStore.token, turmaId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            if (result[0].data !== '') {
                mainStore.removeNotification(loadingNotification);
                mainStore.addNotification({
                    content: result[0].data,
                    color: 'error',
                });
                return result[0].data;
            }
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Turma excluída com sucesso',
                color: 'success',
            });
            return '';
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async cancelarTurma(turmaId) {
        const loadingNotification = { content: 'Cancelando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.cancelarTurma(mainStore.token, turmaId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Turma cancelada com sucesso',
                color: 'success',
            });
            return '';
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async descancelarTurma(turmaId) {
        const loadingNotification = { content: 'Descancelando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.descancelarTurma(mainStore.token, turmaId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Turma descancelada com sucesso',
                color: 'success',
            });
            return '';
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteTurmaAluno(turmaAlunoId) {
        try {
            const response = await api.deleteTurmaAluno(mainStore.token, turmaAlunoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateTurmaAtHomeIniciarAula(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.updateTurmaAtHomeIniciarAula(mainStore.token, payload);
            if (response) {
                mainStore.removeNotification(loadingNotification);
                mainStore.addNotification({
                    content: 'Link da turma atualizado com sucesso!',
                    color: 'success',
                });
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateTurmaLocalAula(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.updateTurmaLocalAula(mainStore.token, payload.turmaId, payload.isAtHome);
            if (response) {
                mainStore.removeNotification(loadingNotification);
                mainStore.addNotification({
                    content: 'Modo de turma atualizado com sucesso!',
                    color: 'success',
                });
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAllTurmasAtHome(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            const response = await api.getAllTurmasAtHome(mainStore.token, payload.curso_id, payload.idioma);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
            return [];
        }
    }
    async getTurmasAlunosVigentes() {
        try {
            const response = await api.getTurmasAlunosVigentes(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], TurmaModule.prototype, "setTurmas", null);
__decorate([
    Mutation
], TurmaModule.prototype, "setTurmaFicha", null);
__decorate([
    Mutation
], TurmaModule.prototype, "setTurmaFichaId", null);
__decorate([
    Mutation
], TurmaModule.prototype, "setTurma", null);
__decorate([
    Action
], TurmaModule.prototype, "createTurma", null);
__decorate([
    Action
], TurmaModule.prototype, "updateTurma", null);
__decorate([
    Action
], TurmaModule.prototype, "ajustarTurma", null);
__decorate([
    Action
], TurmaModule.prototype, "updateCronograma", null);
__decorate([
    Action
], TurmaModule.prototype, "getCronograma", null);
__decorate([
    Action
], TurmaModule.prototype, "getTurmas", null);
__decorate([
    Action
], TurmaModule.prototype, "getTurmasCompativelTransferencia", null);
__decorate([
    Action
], TurmaModule.prototype, "getTurmaQuery", null);
__decorate([
    Action
], TurmaModule.prototype, "getTurmasByProfessorId", null);
__decorate([
    Action
], TurmaModule.prototype, "getTurmasAlunosByTurma", null);
__decorate([
    Action
], TurmaModule.prototype, "getTurmasByProfessor", null);
__decorate([
    Action
], TurmaModule.prototype, "imprimirFichaFrequencia", null);
__decorate([
    Action
], TurmaModule.prototype, "versoFichaFrequencia", null);
__decorate([
    Action
], TurmaModule.prototype, "getTurma", null);
__decorate([
    Action
], TurmaModule.prototype, "setEntregaLivro", null);
__decorate([
    Action
], TurmaModule.prototype, "getTurmaAlunos", null);
__decorate([
    Action
], TurmaModule.prototype, "getTurmaAlunosSimplificado", null);
__decorate([
    Action
], TurmaModule.prototype, "getTurmaAlunosFicha", null);
__decorate([
    Action
], TurmaModule.prototype, "createTurmaAluno", null);
__decorate([
    Action
], TurmaModule.prototype, "getTurmaAlunosAtividade", null);
__decorate([
    Action
], TurmaModule.prototype, "deleteTurma", null);
__decorate([
    Action
], TurmaModule.prototype, "cancelarTurma", null);
__decorate([
    Action
], TurmaModule.prototype, "descancelarTurma", null);
__decorate([
    Action
], TurmaModule.prototype, "deleteTurmaAluno", null);
__decorate([
    Action
], TurmaModule.prototype, "updateTurmaAtHomeIniciarAula", null);
__decorate([
    Action
], TurmaModule.prototype, "updateTurmaLocalAula", null);
__decorate([
    Action
], TurmaModule.prototype, "getAllTurmasAtHome", null);
__decorate([
    Action
], TurmaModule.prototype, "getTurmasAlunosVigentes", null);
TurmaModule = __decorate([
    Module({ name: 'turma' })
], TurmaModule);
export default TurmaModule;
