import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
import _ from 'lodash';
let LivroModule = class LivroModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.livrosVigentes = [];
        this.livros = [];
        this.livrosEGuides = [];
    }
    setLivros(payload) {
        this.livros = payload;
    }
    setLivrosVigentes(payload) {
        this.livrosVigentes = payload;
    }
    setLivrosEGuides(payload) {
        this.livrosEGuides = payload;
    }
    async getLivros() {
        let livros = [];
        if (mainStore.userProfile.company.escola_id == 1) {
            livros = [
                { is_livro: true, nota_preparacao: 1, curso_id: 8, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 26, nota_escrita: 1, nome: 'LIVRO KEEP TALKING 1', nota_fala: 1, edicao: 'SEEDS', nota_homework: 1, livro_codigo: 'KKP0122', nota_leitura: 1, id: 250, codigo_nectar: '1101182', nota_participacao: null, edicao_vigente: true, curso: { id: 8, idioma: 'Inglês', ordem: 8, idade_max: null, nome: 'Keep Talking', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 8, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 26, nota_escrita: 1, nome: 'LIVRO KEEP TALKING 1', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KKT0118', nota_leitura: 1, id: 37, codigo_nectar: '1100050', nota_participacao: null, edicao_vigente: false, curso: { id: 8, idioma: 'Inglês', ordem: 8, idade_max: null, nome: 'Keep Talking', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 4, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 44, nota_escrita: 1, nome: 'LIVRO ESPANHOL 1', nota_fala: 1, edicao: 'SEMILLAS', nota_homework: 1, livro_codigo: 'KLI0122', nota_leitura: null, id: 252, codigo_nectar: '1101181', nota_participacao: null, edicao_vigente: true, curso: { id: 4, idioma: 'Espanhol', ordem: 4, idade_max: 99, nome: 'Espanhol', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 4, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 45, nota_escrita: 1, nome: 'LIVRO ESPANHOL 2', nota_fala: 1, edicao: 'SEMILLAS', nota_homework: 1, livro_codigo: 'KLI0222', nota_leitura: null, id: 254, codigo_nectar: '1101181', nota_participacao: null, edicao_vigente: true, curso: { id: 4, idioma: 'Espanhol', ordem: 4, idade_max: 99, nome: 'Espanhol', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 4, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 46, nota_escrita: 1, nome: 'LIVRO ESPANHOL 3', nota_fala: 1, edicao: 'SEMILLAS', nota_homework: 1, livro_codigo: 'KLI0323', nota_leitura: null, id: 268, codigo_nectar: '1101192', nota_participacao: null, edicao_vigente: true, curso: { id: 4, idioma: 'Espanhol', ordem: 4, idade_max: 99, nome: 'Espanhol', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 1, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 29, nota_escrita: 1, nome: 'LIVRO ADVANCED 1', nota_fala: 1, edicao: '2ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KAD0120', nota_leitura: 1, id: 43, codigo_nectar: '1100060', nota_participacao: null, edicao_vigente: true, curso: { id: 1, idioma: 'Inglês', ordem: 9, idade_max: null, nome: 'Advanced', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 1, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 30, nota_escrita: 1, nome: 'LIVRO ADVANCED 2', nota_fala: 1, edicao: '2ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KAD0220', nota_leitura: 1, id: 44, codigo_nectar: '1100061', nota_participacao: null, edicao_vigente: true, curso: { id: 1, idioma: 'Inglês', ordem: 9, idade_max: null, nome: 'Advanced', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 1, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 31, nota_escrita: 1, nome: 'LIVRO ADVANCED 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KAD0320', nota_leitura: 1, id: 45, codigo_nectar: '1100062', nota_participacao: null, edicao_vigente: false, curso: { id: 1, idioma: 'Inglês', ordem: 9, idade_max: null, nome: 'Advanced', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 1, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 31, nota_escrita: 1, nome: 'LIVRO ADVANCED 3', nota_fala: 1, edicao: 'SEEDS', nota_homework: 1, livro_codigo: 'KAD0325', nota_leitura: 1, id: 285, codigo_nectar: '1101209', nota_participacao: null, edicao_vigente: true, curso: { id: 1, idioma: 'Inglês', ordem: 9, idade_max: null, nome: 'Advanced', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 5, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 24, nota_escrita: 1, nome: 'LIVRO BOOK 2', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KBO0219', nota_leitura: null, id: 32, codigo_nectar: '1100043', nota_participacao: null, edicao_vigente: false, curso: { id: 5, idioma: 'Inglês', ordem: 6, idade_max: null, nome: 'Formação', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 5, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 24, nota_escrita: 1, nome: 'LIVRO BOOK 2', nota_fala: 1, edicao: '4ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KBO0220', nota_leitura: null, id: 33, codigo_nectar: '1100041', nota_participacao: null, edicao_vigente: true, curso: { id: 5, idioma: 'Inglês', ordem: 6, idade_max: null, nome: 'Formação', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 5, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 25, nota_escrita: 1, nome: 'LIVRO BOOK 3', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KBO0320', nota_leitura: null, id: 34, codigo_nectar: '1100042', nota_participacao: null, edicao_vigente: true, curso: { id: 5, idioma: 'Inglês', ordem: 6, idade_max: null, nome: 'Formação', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 8, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 26, nota_escrita: 1, nome: 'LIVRO KEEP TALKING 1', nota_fala: 1, edicao: '4ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KKT0120', nota_leitura: 1, id: 38, codigo_nectar: '1100250', nota_participacao: null, edicao_vigente: false, curso: { id: 8, idioma: 'Inglês', ordem: 8, idade_max: null, nome: 'Keep Talking', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 8, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 27, nota_escrita: 1, nome: 'LIVRO KEEP TALKING 2', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KKT0218', nota_leitura: 1, id: 39, codigo_nectar: '1100051', nota_participacao: null, edicao_vigente: false, curso: { id: 8, idioma: 'Inglês', ordem: 8, idade_max: null, nome: 'Keep Talking', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 8, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 27, nota_escrita: 1, nome: 'LIVRO KEEP TALKING 2', nota_fala: 1, edicao: '4ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KKT0220', nota_leitura: 1, id: 40, codigo_nectar: '1100251', nota_participacao: null, edicao_vigente: true, curso: { id: 8, idioma: 'Inglês', ordem: 8, idade_max: null, nome: 'Keep Talking', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 8, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 28, nota_escrita: 1, nome: 'LIVRO KEEP TALKING 3', nota_fala: 1, edicao: '2ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KKT0320', nota_leitura: 1, id: 41, codigo_nectar: '1100052', nota_participacao: null, edicao_vigente: false, curso: { id: 8, idioma: 'Inglês', ordem: 8, idade_max: null, nome: 'Keep Talking', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 8, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 28, nota_escrita: 1, nome: 'LIVRO KEEP TALKING 3', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KKT0321', nota_leitura: 1, id: 42, codigo_nectar: '1100252', nota_participacao: null, edicao_vigente: true, curso: { id: 8, idioma: 'Inglês', ordem: 8, idade_max: null, nome: 'Keep Talking', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 20, nota_escrita: 1, nome: 'LIVRO TEENS 5', nota_fala: 1, edicao: '2ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0520', nota_leitura: null, id: 26, codigo_nectar: '1100034', nota_participacao: null, edicao_vigente: true, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 21, nota_escrita: 1, nome: 'LIVRO TEENS 6', nota_fala: 1, edicao: '2ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0620', nota_leitura: null, id: 28, codigo_nectar: '1100035', nota_participacao: null, edicao_vigente: true, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 18, nota_escrita: 1, nome: 'LIVRO TEENS 3', nota_fala: 1, edicao: '2ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0318', nota_leitura: null, id: 20, codigo_nectar: null, nota_participacao: null, edicao_vigente: false, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 18, nota_escrita: 1, nome: 'LIVRO TEENS 3', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0319', nota_leitura: null, id: 21, codigo_nectar: '1100032', nota_participacao: null, edicao_vigente: false, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 18, nota_escrita: 1, nome: 'LIVRO TEENS 3', nota_fala: 1, edicao: '4ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0320', nota_leitura: null, id: 22, codigo_nectar: '1100036', nota_participacao: null, edicao_vigente: true, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 16, nota_escrita: 1, nome: 'LIVRO TEENS 1', nota_fala: 1, edicao: '4ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0120', nota_leitura: null, id: 17, codigo_nectar: '1100030', nota_participacao: null, edicao_vigente: true, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 17, nota_escrita: 1, nome: 'LIVRO TEENS 2', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0219', nota_leitura: null, id: 18, codigo_nectar: '1100039', nota_participacao: null, edicao_vigente: false, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 17, nota_escrita: 1, nome: 'LIVRO TEENS 2', nota_fala: 1, edicao: '4ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0220', nota_leitura: null, id: 19, codigo_nectar: '1100031', nota_participacao: null, edicao_vigente: true, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 16, nota_escrita: 1, nome: 'LIVRO TEENS 1', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0119', nota_leitura: null, id: 16, codigo_nectar: null, nota_participacao: null, edicao_vigente: false, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 7, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 41, nota_escrita: 1, nome: 'LIVRO JOVENES 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KJO0220', nota_leitura: 1, id: 56, codigo_nectar: '1100101', nota_participacao: null, edicao_vigente: true, curso: { id: 7, idioma: 'Espanhol', ordem: 3, idade_max: 14, nome: 'Jóvenes', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 7, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 42, nota_escrita: 1, nome: 'LIVRO JOVENES 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KJO0320', nota_leitura: 1, id: 57, codigo_nectar: '1100135', nota_participacao: null, edicao_vigente: true, curso: { id: 7, idioma: 'Espanhol', ordem: 3, idade_max: 14, nome: 'Jóvenes', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 7, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 43, nota_escrita: 1, nome: 'LIVRO JOVENES 4', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KJO0420', nota_leitura: 1, id: 58, codigo_nectar: '1100103', nota_participacao: null, edicao_vigente: true, curso: { id: 7, idioma: 'Espanhol', ordem: 3, idade_max: 14, nome: 'Jóvenes', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 11, nota_audicao: null, nota_revisao: null, curso_etapa_id: 59, nota_escrita: null, nome: 'LIVRO KNOWLEDGE', nota_fala: null, edicao: '', nota_homework: 1, livro_codigo: 'KKN0120', nota_leitura: null, id: 75, codigo_nectar: '1100124', nota_participacao: null, edicao_vigente: true, curso: { id: 11, idioma: 'Inglês', ordem: 5, idade_max: null, nome: 'Knowledge', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 2, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 49, nota_escrita: 1, nome: 'LIVRO ALEMAO 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KAL0120', nota_leitura: null, id: 65, codigo_nectar: '1100120', nota_participacao: null, edicao_vigente: true, curso: { id: 2, idioma: 'Alemão', ordem: 1, idade_max: 99, nome: 'Alemão', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 2, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 50, nota_escrita: 1, nome: 'LIVRO ALEMAO 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KAL0220', nota_leitura: null, id: 66, codigo_nectar: '1100121', nota_participacao: null, edicao_vigente: true, curso: { id: 2, idioma: 'Alemão', ordem: 1, idade_max: 99, nome: 'Alemão', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 2, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 51, nota_escrita: 1, nome: 'LIVRO ALEMAO 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KAL0320', nota_leitura: null, id: 67, codigo_nectar: '1100136', nota_participacao: null, edicao_vigente: true, curso: { id: 2, idioma: 'Alemão', ordem: 1, idade_max: 99, nome: 'Alemão', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 2, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 52, nota_escrita: 1, nome: 'LIVRO ALEMAO 4', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KAL0420', nota_leitura: null, id: 68, codigo_nectar: '1100123', nota_participacao: null, edicao_vigente: true, curso: { id: 2, idioma: 'Alemão', ordem: 1, idade_max: 99, nome: 'Alemão', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 2, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 53, nota_escrita: 1, nome: 'LIVRO ALEMAO 5', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KAL0520', nota_leitura: null, id: 69, codigo_nectar: '1100125', nota_participacao: null, edicao_vigente: true, curso: { id: 2, idioma: 'Alemão', ordem: 1, idade_max: 99, nome: 'Alemão', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 3, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 32, nota_escrita: 1, nome: 'LIVRO CHIQUITOS 1', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KCH0120', nota_leitura: null, id: 46, codigo_nectar: '1100080', nota_participacao: 2, edicao_vigente: true, curso: { id: 3, idioma: 'Espanhol', ordem: 1, idade_max: 8, nome: 'Chiquitos', escola_id: 1, idade_min: 7, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 3, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 33, nota_escrita: 1, nome: 'LIVRO CHIQUITOS 2', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KCH0220', nota_leitura: null, id: 47, codigo_nectar: '1100081', nota_participacao: 2, edicao_vigente: true, curso: { id: 3, idioma: 'Espanhol', ordem: 1, idade_max: 8, nome: 'Chiquitos', escola_id: 1, idade_min: 7, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 3, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 34, nota_escrita: 1, nome: 'LIVRO CHIQUITOS 3', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KCH0320', nota_leitura: null, id: 48, codigo_nectar: '1100084', nota_participacao: 2, edicao_vigente: true, curso: { id: 3, idioma: 'Espanhol', ordem: 1, idade_max: 8, nome: 'Chiquitos', escola_id: 1, idade_min: 7, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 3, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 35, nota_escrita: 1, nome: 'LIVRO CHIQUITOS 4', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KCH0421', nota_leitura: null, id: 49, codigo_nectar: '1100083', nota_participacao: 2, edicao_vigente: true, curso: { id: 3, idioma: 'Espanhol', ordem: 1, idade_max: 8, nome: 'Chiquitos', escola_id: 1, idade_min: 7, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 4, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 44, nota_escrita: 1, nome: 'LIVRO ESPANHOL 1', nota_fala: 1, edicao: '2ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KES0120', nota_leitura: 1, id: 59, codigo_nectar: '1100110', nota_participacao: null, edicao_vigente: true, curso: { id: 4, idioma: 'Espanhol', ordem: 4, idade_max: 99, nome: 'Espanhol', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 4, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 45, nota_escrita: 1, nome: 'LIVRO ESPANHOL 2', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KES0220', nota_leitura: 1, id: 60, codigo_nectar: '1100111', nota_participacao: null, edicao_vigente: true, curso: { id: 4, idioma: 'Espanhol', ordem: 4, idade_max: 99, nome: 'Espanhol', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 4, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 46, nota_escrita: 1, nome: 'LIVRO ESPANHOL 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KES0318', nota_leitura: 1, id: 61, codigo_nectar: '1100119', nota_participacao: null, edicao_vigente: false, curso: { id: 4, idioma: 'Espanhol', ordem: 4, idade_max: 99, nome: 'Espanhol', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 4, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 46, nota_escrita: 1, nome: 'LIVRO ESPANHOL 3', nota_fala: 1, edicao: '2ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KES0320', nota_leitura: 1, id: 62, codigo_nectar: '1100112', nota_participacao: null, edicao_vigente: true, curso: { id: 4, idioma: 'Espanhol', ordem: 4, idade_max: 99, nome: 'Espanhol', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 4, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 47, nota_escrita: 1, nome: 'LIVRO ESPANHOL 4', nota_fala: 1, edicao: '2ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KES0420', nota_leitura: 1, id: 63, codigo_nectar: '1100113', nota_participacao: null, edicao_vigente: true, curso: { id: 4, idioma: 'Espanhol', ordem: 4, idade_max: 99, nome: 'Espanhol', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 6, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 54, nota_escrita: 1, nome: 'LIVRO FRANCÊS 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KFR0120', nota_leitura: null, id: 70, codigo_nectar: '1100130', nota_participacao: null, edicao_vigente: true, curso: { id: 6, idioma: 'Francês', ordem: 1, idade_max: 99, nome: 'Francês', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 6, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 55, nota_escrita: 1, nome: 'LIVRO FRANCÊS 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KFR0220', nota_leitura: null, id: 71, codigo_nectar: '1100131', nota_participacao: null, edicao_vigente: true, curso: { id: 6, idioma: 'Francês', ordem: 1, idade_max: 99, nome: 'Francês', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 6, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 56, nota_escrita: 1, nome: 'LIVRO FRANCÊS 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KFR0320', nota_leitura: null, id: 72, codigo_nectar: '1100132', nota_participacao: null, edicao_vigente: true, curso: { id: 6, idioma: 'Francês', ordem: 1, idade_max: 99, nome: 'Francês', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 6, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 57, nota_escrita: 1, nome: 'LIVRO FRANCÊS 4', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KFR0420', nota_leitura: null, id: 73, codigo_nectar: '1100129', nota_participacao: null, edicao_vigente: true, curso: { id: 6, idioma: 'Francês', ordem: 1, idade_max: 99, nome: 'Francês', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 6, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 58, nota_escrita: 1, nome: 'LIVRO FRANCÊS 5', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KFR0520', nota_leitura: null, id: 74, codigo_nectar: '1100128', nota_participacao: null, edicao_vigente: true, curso: { id: 6, idioma: 'Francês', ordem: 1, idade_max: 99, nome: 'Francês', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 7, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 40, nota_escrita: 1, nome: 'LIVRO JOVENES 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KJO0120', nota_leitura: 1, id: 55, codigo_nectar: '1100100', nota_participacao: null, edicao_vigente: true, curso: { id: 7, idioma: 'Espanhol', ordem: 3, idade_max: 14, nome: 'Jóvenes', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 4, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 48, nota_escrita: 1, nome: 'LIVRO ESPANHOL 5', nota_fala: 1, edicao: '5ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KES0520', nota_leitura: 1, id: 64, codigo_nectar: null, nota_participacao: null, edicao_vigente: true, curso: { id: 4, idioma: 'Espanhol', ordem: 4, idade_max: 99, nome: 'Espanhol', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 12, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 36, nota_escrita: 1, nome: 'LIVRO MUCHACHOS 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KMU0120', nota_leitura: null, id: 50, codigo_nectar: '1100090', nota_participacao: null, edicao_vigente: true, curso: { id: 12, idioma: 'Espanhol', ordem: 2, idade_max: 11, nome: 'Muchachos', escola_id: 1, idade_min: 9, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 12, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 37, nota_escrita: 1, nome: 'LIVRO MUCHACHOS 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KMU0220', nota_leitura: null, id: 51, codigo_nectar: '1100091', nota_participacao: null, edicao_vigente: true, curso: { id: 12, idioma: 'Espanhol', ordem: 2, idade_max: 11, nome: 'Muchachos', escola_id: 1, idade_min: 9, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 19, nota_escrita: 1, nome: 'LIVRO TEENS 4', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0418', nota_leitura: null, id: 23, codigo_nectar: '1100133', nota_participacao: null, edicao_vigente: false, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 19, nota_escrita: 1, nome: 'LIVRO TEENS 4', nota_fala: 1, edicao: '4ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0420', nota_leitura: null, id: 24, codigo_nectar: '1101133', nota_participacao: null, edicao_vigente: true, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 10, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 1, nota_escrita: null, nome: 'LIVRO KINDER 4A', nota_fala: 2, edicao: '2ª EDIÇÃO', nota_homework: null, livro_codigo: 'KKD4A20', nota_leitura: null, id: 1, codigo_nectar: '1100001', nota_participacao: 2, edicao_vigente: true, curso: { id: 10, idioma: 'Inglês', ordem: 1, idade_max: 6, nome: 'Kinder', escola_id: 1, idade_min: 4, infantil: true } },
                { is_livro: true, nota_preparacao: null, curso_id: 10, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 2, nota_escrita: null, nome: 'LIVRO KINDER 4B', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'KKD4B20', nota_leitura: null, id: 2, codigo_nectar: '1100002', nota_participacao: 2, edicao_vigente: true, curso: { id: 10, idioma: 'Inglês', ordem: 1, idade_max: 6, nome: 'Kinder', escola_id: 1, idade_min: 4, infantil: true } },
                { is_livro: true, nota_preparacao: null, curso_id: 10, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 3, nota_escrita: null, nome: 'LIVRO KINDER 5A', nota_fala: 2, edicao: '2ª EDIÇÃO', nota_homework: null, livro_codigo: 'KKD5A20', nota_leitura: null, id: 3, codigo_nectar: '1100003', nota_participacao: 2, edicao_vigente: true, curso: { id: 10, idioma: 'Inglês', ordem: 1, idade_max: 6, nome: 'Kinder', escola_id: 1, idade_min: 4, infantil: true } },
                { is_livro: true, nota_preparacao: null, curso_id: 10, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 4, nota_escrita: null, nome: 'LIVRO KINDER 5B', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'KKD5B20', nota_leitura: null, id: 4, codigo_nectar: '1100004', nota_participacao: 2, edicao_vigente: true, curso: { id: 10, idioma: 'Inglês', ordem: 1, idade_max: 6, nome: 'Kinder', escola_id: 1, idade_min: 4, infantil: true } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 20, nota_escrita: 1, nome: 'LIVRO TEENS 5', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0518', nota_leitura: null, id: 25, codigo_nectar: null, nota_participacao: null, edicao_vigente: false, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 5, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 23, nota_escrita: 1, nome: 'LIVRO BOOK 1', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KBO0119', nota_leitura: null, id: 30, codigo_nectar: '1100045', nota_participacao: null, edicao_vigente: false, curso: { id: 5, idioma: 'Inglês', ordem: 6, idade_max: null, nome: 'Formação', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 5, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 23, nota_escrita: 1, nome: 'LIVRO BOOK 1', nota_fala: 1, edicao: '4ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KBO0120', nota_leitura: null, id: 31, codigo_nectar: '1100040', nota_participacao: null, edicao_vigente: true, curso: { id: 5, idioma: 'Inglês', ordem: 6, idade_max: null, nome: 'Formação', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 30, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 103, nota_escrita: 1, nome: 'LIVRO SEEDS 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KBO0122', nota_leitura: null, id: 119, codigo_nectar: '1101150', nota_participacao: null, edicao_vigente: true, curso: { id: 30, idioma: 'Inglês', ordem: 7, idade_max: 99, nome: 'Seeds', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 30, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 104, nota_escrita: 1, nome: 'LIVRO SEEDS 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KBO0222', nota_leitura: null, id: 120, codigo_nectar: '1101151', nota_participacao: null, edicao_vigente: true, curso: { id: 30, idioma: 'Inglês', ordem: 7, idade_max: 99, nome: 'Seeds', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 30, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 105, nota_escrita: 1, nome: 'LIVRO SEEDS 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KBO0322', nota_leitura: null, id: 121, codigo_nectar: '1101160', nota_participacao: null, edicao_vigente: true, curso: { id: 30, idioma: 'Inglês', ordem: 7, idade_max: 99, nome: 'Seeds', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 30, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 106, nota_escrita: 1, nome: 'LIVRO SEEDS 4', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KBO0422', nota_leitura: null, id: 122, codigo_nectar: '1101162', nota_participacao: null, edicao_vigente: true, curso: { id: 30, idioma: 'Inglês', ordem: 7, idade_max: 99, nome: 'Seeds', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 14, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 21, nota_escrita: 1, nome: 'LIVRO TEENS 6', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTE0618', nota_leitura: null, id: 27, codigo_nectar: null, nota_participacao: null, edicao_vigente: false, curso: { id: 14, idioma: 'Inglês', ordem: 4, idade_max: 14, nome: 'Teens', escola_id: 1, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 13, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 22, nota_escrita: 1, nome: 'LIVRO ON THE ROAD', nota_fala: 1, edicao: '', nota_homework: 1, livro_codigo: 'KON0120', nota_leitura: null, id: 29, codigo_nectar: '1100070', nota_participacao: null, edicao_vigente: true, curso: { id: 13, idioma: 'Inglês', ordem: 10, idade_max: null, nome: 'On The Road', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 10, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 5, nota_escrita: null, nome: 'LIVRO KINDER 6A', nota_fala: 2, edicao: '2ª EDIÇÃO', nota_homework: null, livro_codigo: 'KKD6A20', nota_leitura: null, id: 5, codigo_nectar: '1100005', nota_participacao: 2, edicao_vigente: true, curso: { id: 10, idioma: 'Inglês', ordem: 1, idade_max: 6, nome: 'Kinder', escola_id: 1, idade_min: 4, infantil: true } },
                { is_livro: true, nota_preparacao: null, curso_id: 10, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 6, nota_escrita: null, nome: 'LIVRO KINDER 6B', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'KKD6B20', nota_leitura: null, id: 6, codigo_nectar: '1100006', nota_participacao: 2, edicao_vigente: true, curso: { id: 10, idioma: 'Inglês', ordem: 1, idade_max: 6, nome: 'Kinder', escola_id: 1, idade_min: 4, infantil: true } },
                { is_livro: true, nota_preparacao: 1, curso_id: 15, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 12, nota_escrita: 1, nome: 'LIVRO TWEENS 1', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTW0120', nota_leitura: null, id: 12, codigo_nectar: '1100020', nota_participacao: null, edicao_vigente: true, curso: { id: 15, idioma: 'Inglês', ordem: 3, idade_max: 11, nome: 'Tweens', escola_id: 1, idade_min: 9, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 15, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 13, nota_escrita: 1, nome: 'LIVRO TWEENS 2', nota_fala: 1, edicao: '4ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTW0220', nota_leitura: null, id: 13, codigo_nectar: '1100021', nota_participacao: null, edicao_vigente: true, curso: { id: 15, idioma: 'Inglês', ordem: 3, idade_max: 11, nome: 'Tweens', escola_id: 1, idade_min: 9, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 15, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 13, nota_escrita: 1, nome: 'LIVRO TWEENS 2', nota_fala: 1, edicao: 'SEEDS', nota_homework: 1, livro_codigo: 'KTW0225', nota_leitura: null, id: 287, codigo_nectar: '1101204', nota_participacao: null, edicao_vigente: true, curso: { id: 15, idioma: 'Inglês', ordem: 3, idade_max: 11, nome: 'Tweens', escola_id: 1, idade_min: 9, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 15, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 14, nota_escrita: 1, nome: 'LIVRO TWEENS 3', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTW0320', nota_leitura: null, id: 14, codigo_nectar: '1100022', nota_participacao: null, edicao_vigente: true, curso: { id: 15, idioma: 'Inglês', ordem: 3, idade_max: 11, nome: 'Tweens', escola_id: 1, idade_min: 9, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 15, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 15, nota_escrita: 1, nome: 'LIVRO TWEENS 4', nota_fala: 1, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KTW0420', nota_leitura: null, id: 15, codigo_nectar: '110002', nota_participacao: null, edicao_vigente: true, curso: { id: 15, idioma: 'Inglês', ordem: 3, idade_max: 11, nome: 'Tweens', escola_id: 1, idade_min: 9, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 15, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 15, nota_escrita: 1, nome: 'LIVRO TWEENS 4', nota_fala: 1, edicao: 'SEEDS', nota_homework: 1, livro_codigo: 'KTW0425', nota_leitura: null, id: 286, codigo_nectar: '1101206', nota_participacao: null, edicao_vigente: true, curso: { id: 15, idioma: 'Inglês', ordem: 3, idade_max: 11, nome: 'Tweens', escola_id: 1, idade_min: 9, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 12, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 38, nota_escrita: 1, nome: 'LIVRO MUCHACHOS 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '1100092', nota_leitura: null, id: 52, codigo_nectar: '1100092', nota_participacao: null, edicao_vigente: false, curso: { id: 12, idioma: 'Espanhol', ordem: 2, idade_max: 11, nome: 'Muchachos', escola_id: 1, idade_min: 9, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 12, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 38, nota_escrita: 1, nome: 'LIVRO MUCHACHOS 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KMU0320', nota_leitura: null, id: 53, codigo_nectar: '1100134', nota_participacao: null, edicao_vigente: true, curso: { id: 12, idioma: 'Espanhol', ordem: 2, idade_max: 11, nome: 'Muchachos', escola_id: 1, idade_min: 9, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 12, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 39, nota_escrita: 1, nome: 'LIVRO MUCHACHOS 4', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KMU0420', nota_leitura: null, id: 54, codigo_nectar: '1100093', nota_participacao: null, edicao_vigente: true, curso: { id: 12, idioma: 'Espanhol', ordem: 2, idade_max: 11, nome: 'Muchachos', escola_id: 1, idade_min: 9, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 9, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 7, nota_escrita: 1, nome: 'LIVRO KIDS 1', nota_fala: 2, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KKS0120', nota_leitura: null, id: 7, codigo_nectar: '1100010', nota_participacao: 2, edicao_vigente: true, curso: { id: 9, idioma: 'Inglês', ordem: 2, idade_max: 8, nome: 'Kids', escola_id: 1, idade_min: 7, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 9, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 8, nota_escrita: 1, nome: 'LIVRO KIDS 2', nota_fala: 2, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KKS0220', nota_leitura: null, id: 8, codigo_nectar: '1100011', nota_participacao: 2, edicao_vigente: true, curso: { id: 9, idioma: 'Inglês', ordem: 2, idade_max: 8, nome: 'Kids', escola_id: 1, idade_min: 7, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 9, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 9, nota_escrita: 1, nome: 'LIVRO KIDS 3', nota_fala: 2, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KKS0320', nota_leitura: null, id: 9, codigo_nectar: '1100012', nota_participacao: 2, edicao_vigente: true, curso: { id: 9, idioma: 'Inglês', ordem: 2, idade_max: 8, nome: 'Kids', escola_id: 1, idade_min: 7, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 9, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 10, nota_escrita: 1, nome: 'LIVRO KIDS 4', nota_fala: 2, edicao: '3ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KKS0420', nota_leitura: null, id: 10, codigo_nectar: '1100015', nota_participacao: 2, edicao_vigente: true, curso: { id: 9, idioma: 'Inglês', ordem: 2, idade_max: 8, nome: 'Kids', escola_id: 1, idade_min: 7, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 9, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 11, nota_escrita: 1, nome: 'LIVRO KIDS 5', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KKS0520', nota_leitura: null, id: 11, codigo_nectar: '1100014', nota_participacao: 2, edicao_vigente: true, curso: { id: 9, idioma: 'Inglês', ordem: 2, idade_max: 8, nome: 'Kids', escola_id: 1, idade_min: 7, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 31, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 11, nota_escrita: 1, nome: 'LIVRO ITALIANO 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KIT0123', nota_leitura: null, id: 255, codigo_nectar: '1101185', nota_participacao: null, edicao_vigente: true, curso: { id: 31, idioma: 'Italiano', ordem: 1, idade_max: 99, nome: 'Italiano', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 31, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 11, nota_escrita: 1, nome: 'LIVRO ITALIANO 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '', nota_leitura: null, id: 256, codigo_nectar: '1101188', nota_participacao: null, edicao_vigente: true, curso: { id: 31, idioma: 'Italiano', ordem: 1, idade_max: 99, nome: 'Italiano', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 31, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 11, nota_escrita: 1, nome: 'LIVRO ITALIANO 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '', nota_leitura: null, id: 257, codigo_nectar: '1101189', nota_participacao: null, edicao_vigente: true, curso: { id: 31, idioma: 'Italiano', ordem: 1, idade_max: 99, nome: 'Italiano', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 32, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 11, nota_escrita: 1, nome: 'LIVRO CONTINUA A PARLARE 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '', nota_leitura: 1, id: 258, codigo_nectar: '1101190', nota_participacao: null, edicao_vigente: true, curso: { id: 32, idioma: 'Italiano', ordem: 2, idade_max: 99, nome: 'Continua a parlare', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 32, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 11, nota_escrita: 1, nome: 'LIVRO CONTINUA A PARLARE 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '', nota_leitura: 1, id: 259, codigo_nectar: '1101191', nota_participacao: null, edicao_vigente: true, curso: { id: 32, idioma: 'Italiano', ordem: 2, idade_max: 99, nome: 'Continua a parlare', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 8, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 27, nota_escrita: null, nome: 'LIVRO KEEP TALKING 2', nota_fala: 1, edicao: 'SEEDS', nota_homework: 1, livro_codigo: 'KKP0223', nota_leitura: 1, id: 265, codigo_nectar: '1100251', nota_participacao: null, edicao_vigente: true, curso: { id: 8, idioma: 'Inglês', ordem: 8, idade_max: null, nome: 'Keep Talking', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 8, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 28, nota_escrita: null, nome: 'LIVRO KEEP TALKING 3', nota_fala: 1, edicao: 'SEEDS', nota_homework: 1, livro_codigo: 'KKP0323', nota_leitura: 1, id: 270, codigo_nectar: '1101194', nota_participacao: null, edicao_vigente: true, curso: { id: 8, idioma: 'Inglês', ordem: 8, idade_max: null, nome: 'Keep Talking', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 33, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 112, nota_escrita: 1, nome: 'LIVRO CONTINÚA CONVERSANDO 1', nota_fala: 1, edicao: 'SEMILLAS', nota_homework: 1, livro_codigo: '1', nota_leitura: null, id: 273, codigo_nectar: '1', nota_participacao: null, edicao_vigente: true, curso: { id: 33, idioma: 'Inglês', ordem: 5, idade_max: null, nome: 'Continúa Conversando', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 33, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 113, nota_escrita: 1, nome: 'LIVRO CONTINÚA CONVERSANDO 2', nota_fala: 1, edicao: 'SEMILLAS', nota_homework: 1, livro_codigo: 'KCC0224', nota_leitura: null, id: 281, codigo_nectar: '1101201', nota_participacao: null, edicao_vigente: true, curso: { id: 33, idioma: 'Inglês', ordem: 5, idade_max: null, nome: 'Continúa Conversando', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 1, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 29, nota_escrita: 1, nome: 'LIVRO ADVANCED 1', nota_fala: 1, edicao: 'SEEDS', nota_homework: 1, livro_codigo: 'KAD0123', nota_leitura: 1, id: 275, codigo_nectar: '1101196', nota_participacao: null, edicao_vigente: true, curso: { id: 1, idioma: 'Inglês', ordem: 9, idade_max: null, nome: 'Advanced', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 1, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 30, nota_escrita: 1, nome: 'LIVRO ADVANCED 2', nota_fala: 1, edicao: 'SEEDS', nota_homework: 1, livro_codigo: 'KAD0224', nota_leitura: 1, id: 279, codigo_nectar: '1101200', nota_participacao: null, edicao_vigente: true, curso: { id: 1, idioma: 'Inglês', ordem: 9, idade_max: null, nome: 'Advanced', escola_id: 1, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 2, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 49, nota_escrita: 1, nome: 'LIVRO ALEMAO 1', nota_fala: 1, edicao: 'SAMEN', nota_homework: 1, livro_codigo: 'KDE0123', nota_leitura: null, id: 277, codigo_nectar: '1101198', nota_participacao: null, edicao_vigente: true, curso: { id: 2, idioma: 'Alemão', ordem: 1, idade_max: 99, nome: 'Alemão', escola_id: 1, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: 1, curso_id: 2, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 50, nota_escrita: 1, nome: 'LIVRO ALEMAO 2', nota_fala: 1, edicao: 'SAATEN', nota_homework: 1, livro_codigo: 'KDE0224', nota_leitura: null, id: 283, codigo_nectar: '1101202', nota_participacao: null, edicao_vigente: true, curso: { id: 2, idioma: 'Alemão', ordem: 1, idade_max: 99, nome: 'Alemão', escola_id: 1, idade_min: 15, infantil: false } },
            ];
        }
        else {
            livros = [
                { is_livro: true, nota_preparacao: null, curso_id: 22, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 86, nota_escrita: 1, nome: 'LIVRO TURBINE 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHTUR0101', nota_leitura: null, id: 102, codigo_nectar: '1101146', nota_participacao: null, edicao_vigente: true, curso: { id: 22, idioma: 'Inglês', ordem: 7, idade_max: null, nome: 'Turbine', escola_id: 2, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 22, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 87, nota_escrita: 1, nome: 'LIVRO TURBINE 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHTUR0201', nota_leitura: null, id: 103, codigo_nectar: '1101155', nota_participacao: null, edicao_vigente: true, curso: { id: 22, idioma: 'Inglês', ordem: 7, idade_max: null, nome: 'Turbine', escola_id: 2, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 19, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 74, nota_escrita: 1, nome: 'LIVRO OUT THERE 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '1', nota_leitura: null, id: 90, codigo_nectar: '1101168', nota_participacao: null, edicao_vigente: true, curso: { id: 19, idioma: 'Inglês', ordem: 4, idade_max: 11, nome: 'Out There', escola_id: 2, idade_min: 10, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 16, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 61, nota_escrita: 2, nome: 'LIVRO DISCOVERING 2', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: '1', nota_leitura: null, id: 77, codigo_nectar: '1101166', nota_participacao: 2, edicao_vigente: true, curso: { id: 16, idioma: 'Inglês', ordem: 1, idade_max: 5, nome: 'Discovering', escola_id: 2, idade_min: 4, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 16, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 62, nota_escrita: 2, nome: 'LIVRO DISCOVERING 3', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'PHDIS0301', nota_leitura: null, id: 78, codigo_nectar: '1101156', nota_participacao: 2, edicao_vigente: true, curso: { id: 16, idioma: 'Inglês', ordem: 1, idade_max: 5, nome: 'Discovering', escola_id: 2, idade_min: 4, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 18, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 68, nota_escrita: 2, nome: 'LIVRO CONQUERING 1', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'PHCON0101', nota_leitura: null, id: 84, codigo_nectar: '1101139', nota_participacao: 2, edicao_vigente: true, curso: { id: 18, idioma: 'Inglês', ordem: 3, idade_max: 9, nome: 'Conquering', escola_id: 2, idade_min: 8, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 18, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 69, nota_escrita: 2, nome: 'LIVRO CONQUERING 2', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'PHCON0201', nota_leitura: null, id: 85, codigo_nectar: '1101158', nota_participacao: 2, edicao_vigente: true, curso: { id: 18, idioma: 'Inglês', ordem: 3, idade_max: 9, nome: 'Conquering', escola_id: 2, idade_min: 8, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 18, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 70, nota_escrita: 2, nome: 'LIVRO CONQUERING 3', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'PHCON0301', nota_leitura: null, id: 86, codigo_nectar: '1101140', nota_participacao: 2, edicao_vigente: true, curso: { id: 18, idioma: 'Inglês', ordem: 3, idade_max: 9, nome: 'Conquering', escola_id: 2, idade_min: 8, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 18, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 71, nota_escrita: 2, nome: 'LIVRO CONQUERING 4', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'PHCON0401', nota_leitura: null, id: 87, codigo_nectar: '1101152', nota_participacao: 2, edicao_vigente: true, curso: { id: 18, idioma: 'Inglês', ordem: 3, idade_max: 9, nome: 'Conquering', escola_id: 2, idade_min: 8, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 16, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 60, nota_escrita: 2, nome: 'LIVRO DISCOVERING 1', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'PHDIS0101', nota_leitura: null, id: 76, codigo_nectar: '1101161', nota_participacao: 2, edicao_vigente: true, curso: { id: 16, idioma: 'Inglês', ordem: 1, idade_max: 5, nome: 'Discovering', escola_id: 2, idade_min: 4, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 17, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 64, nota_escrita: 2, nome: 'LIVRO EXPLORING 1', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'PHEXP0101', nota_leitura: null, id: 80, codigo_nectar: '1101141', nota_participacao: 2, edicao_vigente: true, curso: { id: 17, idioma: 'Inglês', ordem: 2, idade_max: 7, nome: 'Exploring', escola_id: 2, idade_min: 6, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 17, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 65, nota_escrita: 2, nome: 'LIVRO EXPLORING 2', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'PHEXP0201', nota_leitura: null, id: 81, codigo_nectar: '1101163', nota_participacao: 2, edicao_vigente: true, curso: { id: 17, idioma: 'Inglês', ordem: 2, idade_max: 7, nome: 'Exploring', escola_id: 2, idade_min: 6, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 17, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 66, nota_escrita: 2, nome: 'LIVRO EXPLORING 3', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'PHEXP0301', nota_leitura: null, id: 82, codigo_nectar: '1101142', nota_participacao: 2, edicao_vigente: true, curso: { id: 17, idioma: 'Inglês', ordem: 2, idade_max: 7, nome: 'Exploring', escola_id: 2, idade_min: 6, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 17, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 67, nota_escrita: 2, nome: 'LIVRO EXPLORING 4', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: 'PHEXP0401', nota_leitura: null, id: 83, codigo_nectar: '1101157', nota_participacao: 2, edicao_vigente: true, curso: { id: 17, idioma: 'Inglês', ordem: 2, idade_max: 7, nome: 'Exploring', escola_id: 2, idade_min: 6, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 20, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 76, nota_escrita: 1, nome: 'LIVRO MOVING UP 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHMUP0101', nota_leitura: null, id: 92, codigo_nectar: '1101143', nota_participacao: null, edicao_vigente: true, curso: { id: 20, idioma: 'Inglês', ordem: 5, idade_max: 14, nome: 'Moving Up', escola_id: 2, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 20, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 77, nota_escrita: 1, nome: 'LIVRO MOVING UP 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHMUP0201', nota_leitura: null, id: 93, codigo_nectar: '1101147', nota_participacao: null, edicao_vigente: true, curso: { id: 20, idioma: 'Inglês', ordem: 5, idade_max: 14, nome: 'Moving Up', escola_id: 2, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 20, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 78, nota_escrita: 1, nome: 'LIVRO MOVING UP 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHMUP0301', nota_leitura: null, id: 94, codigo_nectar: '1101153', nota_participacao: null, edicao_vigente: true, curso: { id: 20, idioma: 'Inglês', ordem: 5, idade_max: 14, nome: 'Moving Up', escola_id: 2, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 19, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 72, nota_escrita: 1, nome: 'LIVRO OUT THERE 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHOT0101', nota_leitura: null, id: 88, codigo_nectar: '1101144', nota_participacao: null, edicao_vigente: true, curso: { id: 19, idioma: 'Inglês', ordem: 4, idade_max: 11, nome: 'Out There', escola_id: 2, idade_min: 10, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 19, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 73, nota_escrita: 1, nome: 'LIVRO OUT THERE 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHOT0201', nota_leitura: null, id: 89, codigo_nectar: '1101159', nota_participacao: null, edicao_vigente: true, curso: { id: 19, idioma: 'Inglês', ordem: 4, idade_max: 11, nome: 'Out There', escola_id: 2, idade_min: 10, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 21, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 82, nota_escrita: 1, nome: 'LIVRO TAKING OFF 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHTOF0101', nota_leitura: null, id: 98, codigo_nectar: '1101137', nota_participacao: null, edicao_vigente: true, curso: { id: 21, idioma: 'Inglês', ordem: 6, idade_max: 99, nome: 'Taking Off', escola_id: 2, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 21, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 83, nota_escrita: 1, nome: 'LIVRO TAKING OFF 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHTOF0201', nota_leitura: null, id: 99, codigo_nectar: '1101138', nota_participacao: null, edicao_vigente: true, curso: { id: 21, idioma: 'Inglês', ordem: 6, idade_max: 99, nome: 'Taking Off', escola_id: 2, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 21, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 84, nota_escrita: 1, nome: 'LIVRO TAKING OFF 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHTOF0301', nota_leitura: null, id: 100, codigo_nectar: '1101136', nota_participacao: null, edicao_vigente: true, curso: { id: 21, idioma: 'Inglês', ordem: 6, idade_max: 99, nome: 'Taking Off', escola_id: 2, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 19, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 75, nota_escrita: 1, nome: 'LIVRO OUT THERE 4', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '1', nota_leitura: null, id: 91, codigo_nectar: '1101169', nota_participacao: null, edicao_vigente: true, curso: { id: 19, idioma: 'Inglês', ordem: 4, idade_max: 11, nome: 'Out There', escola_id: 2, idade_min: 10, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 23, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 88, nota_escrita: 1, nome: 'LIVRO INNER 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHINN0101', nota_leitura: null, id: 104, codigo_nectar: '1101145', nota_participacao: null, edicao_vigente: true, curso: { id: 23, idioma: 'Inglês', ordem: 8, idade_max: null, nome: 'Inner', escola_id: 2, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 27, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 97, nota_escrita: 1, nome: 'LIVRO ESPANHOL 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHESP0101', nota_leitura: null, id: 113, codigo_nectar: '1101134', nota_participacao: null, edicao_vigente: true, curso: { id: 27, idioma: 'Espanhol', ordem: 1, idade_max: 99, nome: 'Espanhol básico', escola_id: 2, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 27, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 98, nota_escrita: 1, nome: 'LIVRO ESPANHOL 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHESP0201', nota_leitura: null, id: 114, codigo_nectar: '1101149', nota_participacao: null, edicao_vigente: true, curso: { id: 27, idioma: 'Espanhol', ordem: 1, idade_max: 99, nome: 'Espanhol básico', escola_id: 2, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 27, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 99, nota_escrita: 1, nome: 'LIVRO ESPANHOL 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHESP0301', nota_leitura: null, id: 115, codigo_nectar: '1101165', nota_participacao: null, edicao_vigente: true, curso: { id: 27, idioma: 'Espanhol', ordem: 1, idade_max: 99, nome: 'Espanhol básico', escola_id: 2, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 24, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 91, nota_escrita: 1, nome: 'LIVRO FRANCÊS 1', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHFRA0101', nota_leitura: null, id: 107, codigo_nectar: '1101135', nota_participacao: null, edicao_vigente: true, curso: { id: 24, idioma: 'Francês', ordem: 1, idade_max: 99, nome: 'Francês básico', escola_id: 2, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 24, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 92, nota_escrita: 1, nome: 'LIVRO FRANCÊS 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHFRA0201', nota_leitura: null, id: 108, codigo_nectar: '1101148', nota_participacao: null, edicao_vigente: true, curso: { id: 24, idioma: 'Francês', ordem: 1, idade_max: 99, nome: 'Francês básico', escola_id: 2, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 24, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 93, nota_escrita: 1, nome: 'LIVRO FRANCÊS 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHFRA0301', nota_leitura: null, id: 109, codigo_nectar: '1101164', nota_participacao: null, edicao_vigente: true, curso: { id: 24, idioma: 'Francês', ordem: 1, idade_max: 99, nome: 'Francês básico', escola_id: 2, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 21, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 85, nota_escrita: 1, nome: 'LIVRO TAKING OFF 4', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHTOF0401', nota_leitura: null, id: 101, codigo_nectar: '1101154', nota_participacao: null, edicao_vigente: true, curso: { id: 21, idioma: 'Inglês', ordem: 6, idade_max: 99, nome: 'Taking Off', escola_id: 2, idade_min: 15, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 25, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 94, nota_escrita: 1, nome: 'LIVRO FRANCÊS 4', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KFR0420', nota_leitura: null, id: 110, codigo_nectar: '1101178', nota_participacao: null, edicao_vigente: true, curso: { id: 25, idioma: 'Francês', ordem: 2, idade_max: null, nome: 'Francês intermediário', escola_id: 2, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 25, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 95, nota_escrita: 1, nome: 'LIVRO FRANCÊS 5', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'KFR0520', nota_leitura: null, id: 111, codigo_nectar: '1101179', nota_participacao: null, edicao_vigente: true, curso: { id: 25, idioma: 'Francês', ordem: 2, idade_max: null, nome: 'Francês intermediário', escola_id: 2, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 20, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 79, nota_escrita: 1, nome: 'LIVRO MOVING UP 4', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: 'PHMUP0401', nota_leitura: null, id: 95, codigo_nectar: '1101170', nota_participacao: null, edicao_vigente: true, curso: { id: 20, idioma: 'Inglês', ordem: 5, idade_max: 14, nome: 'Moving Up', escola_id: 2, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 16, nota_audicao: 2, nota_revisao: null, curso_etapa_id: 63, nota_escrita: 2, nome: 'LIVRO DISCOVERING 4', nota_fala: 2, edicao: '1ª EDIÇÃO', nota_homework: null, livro_codigo: '1', nota_leitura: null, id: 79, codigo_nectar: '1101167', nota_participacao: 2, edicao_vigente: true, curso: { id: 16, idioma: 'Inglês', ordem: 1, idade_max: 5, nome: 'Discovering', escola_id: 2, idade_min: 4, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 23, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 89, nota_escrita: 1, nome: 'LIVRO INNER 2', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '1', nota_leitura: null, id: 105, codigo_nectar: '1101173', nota_participacao: null, edicao_vigente: true, curso: { id: 23, idioma: 'Inglês', ordem: 8, idade_max: null, nome: 'Inner', escola_id: 2, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 23, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 90, nota_escrita: 1, nome: 'LIVRO INNER 3', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '1', nota_leitura: null, id: 106, codigo_nectar: '1101174', nota_participacao: null, edicao_vigente: true, curso: { id: 23, idioma: 'Inglês', ordem: 8, idade_max: null, nome: 'Inner', escola_id: 2, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 28, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 100, nota_escrita: 1, nome: 'LIVRO ESPANHOL 4', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '1', nota_leitura: null, id: 116, codigo_nectar: '1101175', nota_participacao: null, edicao_vigente: true, curso: { id: 28, idioma: 'Espanhol', ordem: 2, idade_max: null, nome: 'Espanhol intermediário', escola_id: 2, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 28, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 101, nota_escrita: 1, nome: 'LIVRO ESPANHOL 5', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '1', nota_leitura: null, id: 117, codigo_nectar: '1101176', nota_participacao: null, edicao_vigente: true, curso: { id: 28, idioma: 'Espanhol', ordem: 2, idade_max: null, nome: 'Espanhol intermediário', escola_id: 2, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 29, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 102, nota_escrita: 1, nome: 'LIVRO ESPANHOL 6', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '1', nota_leitura: null, id: 118, codigo_nectar: '1101177', nota_participacao: null, edicao_vigente: true, curso: { id: 29, idioma: 'Espanhol', ordem: 3, idade_max: null, nome: 'Espanhol avançado', escola_id: 2, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 26, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 96, nota_escrita: 1, nome: 'LIVRO FRANCÊS 6', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '1', nota_leitura: null, id: 112, codigo_nectar: '1101180', nota_participacao: null, edicao_vigente: true, curso: { id: 26, idioma: 'Francês', ordem: 3, idade_max: null, nome: 'Francês avançado', escola_id: 2, idade_min: null, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 20, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 80, nota_escrita: 1, nome: 'LIVRO MOVING UP 5', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '1', nota_leitura: null, id: 96, codigo_nectar: '1101171', nota_participacao: null, edicao_vigente: true, curso: { id: 20, idioma: 'Inglês', ordem: 5, idade_max: 14, nome: 'Moving Up', escola_id: 2, idade_min: 12, infantil: false } },
                { is_livro: true, nota_preparacao: null, curso_id: 20, nota_audicao: 1, nota_revisao: null, curso_etapa_id: 81, nota_escrita: 1, nome: 'LIVRO MOVING UP 6', nota_fala: 1, edicao: '1ª EDIÇÃO', nota_homework: 1, livro_codigo: '1', nota_leitura: null, id: 97, codigo_nectar: '1101172', nota_participacao: null, edicao_vigente: true, curso: { id: 20, idioma: 'Inglês', ordem: 5, idade_max: 14, nome: 'Moving Up', escola_id: 2, idade_min: 12, infantil: false } },
            ];
        }
        this.setLivros(livros);
        const livrosVigentes = _.filter(livros, (item) => item.edicao_vigente);
        this.setLivrosVigentes(livrosVigentes);
        // nao precisa bater na api
        // try {
        //   const response = await api.getLivros(mainStore.token);
        //   if (response) {
        //     this.setLivros(response.data);
        //     const livrosVigentes = _.filter(
        //       response.data,
        //       (item) => item.edicao_vigente
        //     );
        //     this.setLivrosVigentes(livrosVigentes);
        //   }
        // } catch (error) {
        //   await mainStore.checkApiError(error);
        // }
    }
    async getLivrosEGuides(payload) {
        try {
            if (this.livrosEGuides.length > 0) {
                if (this.livrosEGuides[0].escola_id === payload.escolaId) {
                    return;
                }
            }
            const response = await api.getLivrosEGuides(mainStore.token);
            if (response) {
                this.setLivrosEGuides(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getListeningLivros(id) {
        try {
            const response = await api.getListeningLivros(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getListening(codigo) {
        try {
            const response = await api.getListening(mainStore.token, codigo);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getGlossary(codigo) {
        try {
            const response = await api.getGlossary(mainStore.token, codigo);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createListening(payload) {
        try {
            const response = await api.createListening(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], LivroModule.prototype, "setLivros", null);
__decorate([
    Mutation
], LivroModule.prototype, "setLivrosVigentes", null);
__decorate([
    Mutation
], LivroModule.prototype, "setLivrosEGuides", null);
__decorate([
    Action
], LivroModule.prototype, "getLivros", null);
__decorate([
    Action
], LivroModule.prototype, "getLivrosEGuides", null);
__decorate([
    Action
], LivroModule.prototype, "getListeningLivros", null);
__decorate([
    Action
], LivroModule.prototype, "getListening", null);
__decorate([
    Action
], LivroModule.prototype, "getGlossary", null);
__decorate([
    Action
], LivroModule.prototype, "createListening", null);
LivroModule = __decorate([
    Module({ name: 'livro' })
], LivroModule);
export default LivroModule;
