import { __decorate } from "tslib";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { api } from '@/api';
import { mainStore } from '@/utils/store-accessor';
let AsaasConfiguracaoModule = class AsaasConfiguracaoModule extends VuexModule {
    setAsaasConfiguracao(payload) {
        this.asaasConfiguracao = payload;
    }
    async getAsaasConfiguracaoByFinanceiroParcela(payload) {
        try {
            const response = await api.getAsaasConfiguracaoByFinanceiroParcela(mainStore.token, payload.financeiroParcelaId);
            if (response) {
                this.setAsaasConfiguracao(response.data);
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAsaasConfiguracao() {
        try {
            const response = await api.getAsaasConfiguracao(mainStore.token);
            if (response) {
                this.setAsaasConfiguracao(response.data);
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createAsaasConfiguracao(payload) {
        try {
            const response = await api.createAsaasConfiguracao(mainStore.token, payload);
            if (response && response.data) {
                this.setAsaasConfiguracao(response.data);
                mainStore.addNotification({
                    content: 'Drive Pay criada com sucesso',
                    color: 'success',
                });
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateAsaasConfiguracao(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.updateAsaasConfiguracao(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Drive Pay atualizada com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response?.data?.detail || 'Erro ao atualizar configuração',
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], AsaasConfiguracaoModule.prototype, "setAsaasConfiguracao", null);
__decorate([
    Action
], AsaasConfiguracaoModule.prototype, "getAsaasConfiguracaoByFinanceiroParcela", null);
__decorate([
    Action
], AsaasConfiguracaoModule.prototype, "getAsaasConfiguracao", null);
__decorate([
    Action
], AsaasConfiguracaoModule.prototype, "createAsaasConfiguracao", null);
__decorate([
    Action
], AsaasConfiguracaoModule.prototype, "updateAsaasConfiguracao", null);
AsaasConfiguracaoModule = __decorate([
    Module({ name: 'asaasConfiguracao' })
], AsaasConfiguracaoModule);
export default AsaasConfiguracaoModule;
